.divcontainer {
    background-color: transparent;
    background-size: cover;
    display: flex;
    width: 100vw;
    margin-top: 10rem;
    color: white;


}

.textContainer {
    width: 50vw; 
    margin-left: 2rem;   
}
.textabout {
    color: rgb(255, 255, 255);
    font-size: 32px;
    font-family: Arial, sans-serif;
    text-align: center;
    box-shadow: 3px 3px 8px rgba(255, 255, 255, 2);
    padding: 10px;
    line-height: 1.5;
    background-color: rgba(0, 0, 0, 0.5);

}

.image{
    display: flex;
    width: fit-content;
    height: fit-content;    
    right: 4rem;
    position: fixed;
}
.image img{    
    filter: drop-shadow(15px 0px 10px rgba(255, 255, 255, 0.5)); 
    width: 90%;
    height:90%;
}


.divmayor {
    background-color: transparent;
    display: flex;
    flex-direction: column;
}

.loadingContainer {
    display: flex;
    flex-direction: column;
    margin-top: 10%;
    width: 50%;
    align-items: center;
    justify-content: center;
    background-color: transparent;
}
.loadingContainer p{
    text-align: center;
    text-decoration:solid;
    font-size: 24px;
}

.loadingSpinner {
    border: 4px solid rgba(0, 0, 0, 0.3);
    border-top: 4px solid #f5f5f5;
    border-radius: 50%;
    width: 50px;
    height: 50px;
    animation: spin 2s linear infinite;
    transform: translate(-50%, -50%);
}

@keyframes spin {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}


@media screen and (max-device-width: 768px) and (orientation: portrait){
    .divcontainer {
        flex-direction: column;
        margin-top: 5rem;
    }

    .textContainer {
        width: 90vw;
        margin-left: 0;
    }
    .divmayor{
        width: 100vw;
    }

    .image {
        position:relative;
        width: 100vw;
        height: 100vh;
        box-shadow: none;
    }

    .textabout {
        width: 100%;
        font-size: 24px;
        padding: 5px;
    }

}