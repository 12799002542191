.divMayor{
    width: 100vw;
    display: flex;
}
.projectsContainer{
    display: grid;
    margin-left: 1rem;
    grid-template-columns: repeat(3, 0.3fr);
    grid-gap: 1rem;
    justify-self: center;
    margin-top: 9rem;
    padding-bottom: 2rem;

}
.projectCard{
    width: fit-content;
    height: fit-content;
    padding:0.5rem;
    background-color: rgba(2, 3, 26, 0.5);
    border-radius: 10px;
    box-shadow: -2px -2px 2px 2px rgba(255, 255, 255, 0.6);
}
.projectCard img{
    align-self: center;
    justify-self: center;
    width: auto;
    height: 180px;
}
.projectCard h2{
    font-weight: bold;
    font-style: italic;
    text-decoration: underline;
    color: white;
}
.projectCard p{
    color: white
}

.linksCont h3 {
    color: white;
}
.linksCont ul {
    list-style-type: none; 
    padding: 0; 
}


.linksCont ul li {
    color: white;
}

.linksCont ul li a {
    color: white;
    text-decoration: none; 
}


.linksCont ul li a:hover {
    text-decoration: underline; 
}

.loadingContainer {
    align-content: center;
    justify-content: center;
    padding: 20px;
    margin-top: 10rem;
    margin-left: 40%;
}

.loadingSpinner {
    border: 4px solid rgba(255, 255, 255, 0.6);
    margin-left: 40%;
    margin-top: 30%;
    border-top: 4px solid #3498db;
    border-radius: 50%;
    width: 50px;
    height: 50px;
    animation: spin 2s linear infinite;
    transform: translate(-50%, -50%);
}
.loadingContainer p{
    display: flex;
    color: rgb(255, 255, 255);
    text-align: center;
    font-weight: bold;
    font-size: 24px;
    font-family: 'Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande', 'Lucida Sans Unicode', Geneva, Verdana, sans-serif;
}

@keyframes spin {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}

@media screen and (max-device-width: 768px) and (orientation: portrait) {
    .divMayor {
        margin-top: 5rem; 
        flex-direction: column; 
        align-items: center; 
    }

    .projectsContainer {
        grid-template-columns: repeat(1, 1fr); 
        margin-left: 1rem;
        grid-gap: 1rem; 
    }

    .projectCard {
        width: 100%; 
        margin-bottom: 1rem; 
    }
}