:root {
  background-image: url('../public/imagenes/fondo.jpeg');
  background-color: rgba(0, 0, 0, 0.2); /* Color de fondo con transparencia */
  background-blend-mode: overlay; /* Mezcla la imagen con el color */
  margin: 0;
}

body {
  margin: 0;
  padding: 0;
  width: 100vw;
  height: 100vh;
}
