.DivMayor{
    display: flex;
    flex-direction: column;
    width: 100vw;
    height: 100vh;
    padding: 0px;
    justify-items: center;

}
.divForm{
    background-color: rgba(95, 52, 252, 0.2);
    padding: 10px;
    border-radius: 10px;
    width: fit-content;
    height: 23rem;
}
.errorText{
    width: fit-content;
    height: 20px;
}
.Divcontainer{
    display: flex;
    margin-top: 8rem;
    width: fit-content;
    height: fit-content;
    padding: 2rem;
    align-self: center;

}
.labelContainer{
    background-color: none;
    justify-content: center;
    width: fit-content;
}
.label{
    color:white;
    font-family: Georgia, 'Times New Roman', Times, serif;
    background-color: none;
    padding-right: 1rem;
}
.labelContainer input{
    color: black;
    
}
.labelContainer p{
    color: white;
    background-color: none;
}
.inputText {
    width: 40rem;
    height: 10rem;
    padding: 0; 
    margin: 0; 
    border: none; 
    line-height: normal; 
    resize: none; 
    overflow-y: scroll;
}
.divButton{
    display: flex;
}
.divButton button{
    margin-left: 40%;
    width: 10rem;
    height: 3rem;
    border-radius: 2rem;
    border: solid black;
}
.divButton button:hover{
    cursor: pointer;
    color: white;
    background-color: green;
    box-shadow: yellowgreen 3px 3px 3px 3px;
}


@media screen and (max-width: 768px){
    .DivMayor {
        display: flex;
        height: 100vh;
    }

    .divForm {
        margin-left: 0; 
        width: 100%;
    }

    .Divcontainer {
        justify-content: center;
        width: 90vw; 
        height: fit-content; 
    }

    .labelContainer {
        justify-content: center; 
        width: 100%; 
    }

    .label {
        background-color: none;
    }

    .labelContainer input {
        color: black;
        background-color: white;
    }

    .labelContainer p {
        background-color: none;
    }

    .inputText {
        width: 100%; 
        height: 15rem;
    }

    .divButton {
        display: flex;
        flex-direction: column; 
        align-items: center;
    }

    .divButton button {
        margin: 1rem 0; 
        border-radius: 2rem;
    }
    .Footer{
        display: grid;
        grid-template-columns: repeat(1,1fr);
        bottom: 0;
    }
}