.divMayor{
    display: flex;
    flex-direction: column;
}
.divContainer{
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    margin-left: 1rem;
    grid-gap: 1rem;
    padding-top: 1rem;
    padding-bottom: 1rem;

}
.divDownload{
    display: grid;
    grid-template-columns: repeat(2, 0.5fr);
    margin-top: 6.5rem;
    color:white;
    height: fit-content;
    width: fit-content;
    margin-left: 20%;
    justify-content: center; 
    align-content: center; 

}
.divDownload p {
    width: max-content;
    height: max-content;
    background-color: rgba(0, 0, 0, 0.5);

}
.downloadButton{
    margin: auto;
    margin-left: 2rem;
    height: fit-content;
    width: fit-content;

}
.downloadButton:hover{
    background-color: black;
    color: white;
    cursor: pointer;
}
.skillCard{
    display: flex;
    flex-direction: column;
    width: 18rem;
    height: 18rem;
    background-color: rgba(255, 255, 255, 1);
    box-shadow: 1px 1px 1px 1px rgb(79, 136, 241);
    border-radius: 50%;
}
.skillCard h3{
    padding-top: 1rem;
    align-self: center;
    font-family: 'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif;
    font-size: 24px;
    text-decoration: underline;
    color: black;
}
.skillCard p{
    align-self: center;
    font-family: 'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif;
    font-size: 16px;
    color: black;
}
.skillLogo{
    align-self: center;
    width: fit-content;
    height: 7rem;
}
.skillLevel{
    display: flex;
    align-self: center;
    width: fit-content;
    height: fit-content;

}

.loadingContainer {
    align-content: center;
    justify-content: center;
    padding: 20px;
    margin-left: 40%;
}

.loadingSpinner {
    border: 4px solid rgba(255, 255, 255, 0.6);
    margin-left: 13%;
    margin-top: 30%;
    border-top: 4px solid #3498db;
    border-radius: 50%;
    width: 50px;
    height: 50px;
    animation: spin 2s linear infinite;
    transform: translate(-50%, -50%);
}
.loadingContainer p{
    display: flex;
    color: rgb(255, 255, 255);
    text-align: center;
    font-weight: bold;
    font-size: 24px;
    font-family: 'Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande', 'Lucida Sans Unicode', Geneva, Verdana, sans-serif;
}

@keyframes spin {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}


@media screen and (max-device-width: 768px) and (orientation: portrait){
    .divContainer {
        margin-top: 1rem;
        grid-template-columns: repeat(2, 1fr); 
    }

    .divDownload {
        grid-template-columns: repeat(1, 1fr); 
        margin-left: 0; 
        text-align: center; 
    }

    .skillCard {
        justify-content: center;
        width: 50vw;

    }
}