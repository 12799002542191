.navMayor {
  display: flex;
  position: fixed;
  width: 100vw;
  height: fit-content;
  background-color: rgb(0, 0, 0);   
  padding: 0 ; 
  transition: box-shadow 0.3s;
  z-index: 9999;
}
.navMayor.scrolled {
  box-shadow: 0px 0px 25px rgba(255, 255, 255, 2); 
  border-bottom: white solid 1px;
}
.navbutton {
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 6rem;
  height: 3rem;
  margin-left: auto;
  margin-right: auto;
  margin-top: 2rem;
  margin-right: 1rem;
  padding: 0.5rem 1rem;
  border-radius: 0.9rem;
}

.navbutton:hover {
  box-shadow: 0 6px 9px 0 rgb(255, 255, 255), 0 17px 50px 0 rgba(0, 0, 0, 0.19);
  background-color: rgb(132, 212, 101);
  color: white;
}

.container {
  width: 100vw;
  display: flex;
  justify-content: center;
  align-items: center;
  top: 0;
  left: 0;
  right: 0;
  background-color: none;
  padding-bottom: 1rem;
}

.Lanbutton {
  height: 3rem;
  cursor: pointer;
  margin-left: auto;
  margin-right: auto;
  margin-top: 2rem;
  padding: 0.5rem 1rem;
  border-radius: 0.9rem;
}

.Lanbutton:hover {
  box-shadow: 0 12px 16px 0 rgba(0, 0, 0, 0.24), 0 17px 50px 0 rgba(0, 0, 0, 0.19);
  background-color: rgb(0, 0, 0);
  color: white;
  border-radius: 0.9rem;
}


@media screen and (max-width: 768px) {

  .navbutton {
    margin: 0;
    padding: 0;
  }

  .Lanbutton {
    margin: 0;
  }

  .container {
    align-items: flex-start;
  }
}